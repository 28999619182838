/* Remove browser defaults */
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
 
/* Style App.js wrapper */
.App {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
 
/* Tab Container */
.Tabs {
  width: 90%;
  height: 90%;
  min-height: 400px;
  /* background: #053742; */
  background: #ffffff;
  margin: 3.5rem auto 1.5rem;
  padding: 2rem 1rem;
  color: #020b0d;
  border-radius: 2rem;
}
 
.react-tabs {
  -webkit-tap-highlight-color: transparent;
}
 
.react-tabs__tab-list {
  width: 60%;
  margin: 0 auto 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #020d13;
  border-radius: 2rem;
  padding-left: 0px;
}
 
.react-tabs__tab {
  width: 50%;
  padding: 1rem;
  list-style: none;
  text-align: center;
  cursor: pointer;
  transition: all 0.7s;
}
 
.react-tabs__tab--selected {
  background: #8db0e5;
}
 
.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

.center {
  margin-left: auto;
  margin-right: auto;
}
 
.react-tabs__tab:hover {
  background: rgba(139, 140, 140, 0.855);
}
 
.react-tabs__tab:first-child {
  border-bottom-left-radius: 2rem;
  border-top-left-radius: 2rem;
}
 
.react-tabs__tab:last-child {
  border-bottom-right-radius: 2rem;
  border-top-right-radius: 2rem;
}
 
.react-tabs__tab-panel {
  display: none;
}
 
.react-tabs__tab-panel--selected {
  display: block;
  font-size: 2rem;
  text-align: center;
}

table {
  width: 80%
}

 td,  th {
  border: 1px solid #ddd;
  padding: 8px;
  font-size: 0.5em;
}

 tr:nth-child(even){background-color: #f2f2f2;}

 tr:hover {background-color: #ddd;}

 th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #8db0e5;
  color: white;
}